import React from 'react'
import footerLogo from './image/footer-logo.png'
import whatsappIcon from './image/whatsappbtn.png'
// import telephoneIcon from './image/tel_logo.png'



export default function Footer() {
    return (
        <>
            <footer>
                <div className="wrapper">
                    <div className="row m-0 align-items-center">
                        <img src={footerLogo} alt="Logo" className="footer-logo" />
                        <img src={whatsappIcon} alt="Logo" className="whatsapp-logo" />
                        <a href="https://wa.me/919346403176" target="_blank" rel="noopener noreferrer">  <p>+91 93464 03176</p></a>

                    </div>
                </div>
                {/* <div className='call-to-action-head' >
                    <a href="https://wa.me/919346403176" target="_blank" rel="noopener noreferrer">
                        <img src={whatsappIcon} alt="Logo" className="call-to-action" />
                    </a>
                    <a href="tel:+919346403176" target="_blank" rel="noopener noreferrer">
                        <img src={telephoneIcon} alt="Logo" className="call-to-action" />
                    </a> */}



                    {/* <a target="_blank" href="tel:+91 6302583224" class="py-2 px-3 d-block d-sm-none" >
                    <img src="../../../assets/images/callChat.svg">
                    </a>
                    <a target="_blank" class="py-2 px-3 cursor-pointer d-none d-sm-block" >
                    <img src="../../../assets/images/callChat.svg">
                    </a> */}
                {/* </div> */}
                {/* <div class="row m-0"><a target="_blank" href="#" class="py-2 px-3 d-block d-sm-none" >
                    <img src="../../../assets/images/whatsappChat.svg">
                    </a>
                    <a target="_blank" href="#" class="py-2 px-3 d-none d-sm-block" >
                    <img src="../../../assets/images/whatsappChat.svg">
                    </a>
                </div> */}

            </footer >
        </>

    )
}
